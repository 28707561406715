import type { VerticalNavItems } from '@/@layouts/types';

export default [
	{
		title: 'Dashboard',
		to: { name: 'index' },
		icon: { icon: 'tabler-dashboard' },
		action: 'read',
		subject: 'work-order',
	},
	{
		title: 'Workflow',
		icon: { icon: 'tabler-checklist' },
		children: [
			{
				title: 'Wash Bay',
				to: { name: 'work-order-status-processing' },
				icon: { icon: 'tabler-file' },
				action: 'read',
				subject: 'work-order',
			},
			{
				title: 'Ready for Collection',
				to: { name: 'work-order-status-completed' },
				icon: { icon: 'tabler-check' },
				action: 'read',
				subject: 'work-order',
			},
			{
				title: 'Collected',
				to: { name: 'work-order-status-collected' },
				icon: { icon: 'tabler-checkup-list' },
				action: 'read',
				subject: 'work-order',
			},
		],
	},
	{
		title: 'Cashup',
		to: { name: 'cashup' },
		icon: { icon: 'tabler-zoom-money' },
		action: 'read',
		subject: 'work-order',
	},
] as VerticalNavItems;
